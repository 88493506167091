<template>
	<div class="case" v-title="'装修案例——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
		    <img class="bran" src="../image/case/header_pc.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/case/header_mob.jpg"
				alt=""
			/>
		</div>
        <div class="content f-y-s-c">
			<div class="filter">
                <CaseCategory :showAddress="showAddress" :showDesLv="false" :showStore="false"  addressTitle="位置" @callback="callback"></CaseCategory>
            </div>
            <div v-if="caseList.length" class="list">
                <div
                    v-for="(item,index) in caseList"
                    :key="index" 
                    class="list-item"
                    >
                    <div class="img-box">
                        <img @click="navToDetail(item.id)" class="item-img" :src="item.coverUrl" alt="">
                    </div>
                    
                    <span @click="navToDetail(item.id)" class="text1">{{item.villageName}}</span>
                    <div class="box1">
                        <HouseInfo :data="item" type="row" textColor="#444444" :textSize="24"></HouseInfo>
                    </div>
                    <div class="mob-box1">
                        <HouseInfo :data="item" type="row" textColor="#444444" :textSize="14"></HouseInfo>
                    </div>
                    <div class="bottom f-x-b-c">
                        <div 
                            class="dialog f-y-c-c" 
                            :class="showDiaInd==index ? 'show-dialog':''"
                            @mouseenter="showDiaInd=index" 
                            @mouseleave="showDiaInd=-1"
                            >
                            <img @click.stop="navToDesignDet(item.designerId)" class="img1" :src="formatAvtar(item.designerId)" alt="">
                            <span @click.stop="navToDesignDet(item.designerId)" class="text2">{{item.designerName}}</span>
                            <span class="text3">{{ formatLv(item.designerId) }}</span>
                            <div class="options f-x-b-c">
                                <div @click.stop="openDialog('design')" class="btn1 f-x-c-c">预约TA</div>
                                <div @click.stop="navToDesignDet(item.designerId)" class="btn2 f-x-c-c">了解TA</div>
                            </div>
                            <div class="jiao"></div>
                        </div>
                        <div class="info f-x-s-c" @mouseenter="showDiaInd=index" @mouseleave="showDiaInd=-1">
                            <div class="avtar">
                                <img class="img" :src="formatAvtar(item.designerId)" alt="">
                            </div>
                            <span class="name">{{item.designerName}}</span>
                        </div>
                        
                        <div @click.stop="openDialog('plan')" class="msg f-x-c-c">免费获取装修方案</div>
                       
                    </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据" class="empty" ></el-empty>
            <Pagination
                :page-size="params.size"
                :current-page="params.current"
                :total="total"
                @current-change="changeCurrent"
            ></Pagination>
		</div>

		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>

        <DialogForm ref="refDialogForm" adUnitCode="MObN"></DialogForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import HouseInfo from '../components/HouseInfo.vue'
import CaseCategory from '../components/CaseCategory.vue'
import {useSalesRoomDict,useDesDict,useDesRankDict} from '@/utils/frequent.js'
import {codeToText} from 'element-china-area-data'
import { getCaseData } from '@/api/case.js'
import {mapState} from 'vuex'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
        CaseCategory,
        HouseInfo
	},
	data() {
		return {
            codeToText,
            showDiaInd:-1,
			caseList: [],
            desList:[],
            rankList:[],
            total:0,
            params:{
                size:12,
                current:1, 
                salesRoomNo:'',
                houseArea:'',
                designStyle:'',
                houseType:'',
                
            },
           
		}
	},
    async mounted(){
        this.getCaseList()
        this.desList = await useDesDict()
        this.rankList = await useDesRankDict()
    },
    computed:{
        ...mapState(['curCityInfo']),
        showAddress(){
            if(this.curCityInfo.value=='00') return true
            else return false
        }
    },
	methods: {
        navToDetail(id){
            this.$router.push({
                path:'/investor/detail',
                query:{
                    id
                }
            })
        },
        navToDesignDet(id){
            this.$router.push({
                path:'/design/detail',
                query:{
                    id
                }
            })
        },
        openDialog(type){
            this.$refs.refDialogForm.showDialog(type)
        },
        changeCurrent(data){
            this.params.current = data
            this.getCaseList()
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
        },
        async getCaseList(){
            try{
                const res = await getCaseData(this.params)
                if(res.code==0){
                        this.caseList = res.data.records
                        this.total = res.data.total
                }
            }catch(err){
                console.log(err);
            }
        },
        formatAvtar(id){
            const res = this.desList.find(item=>item.id == id)
            if(res) return res.headerImgUrl
            // else return 
        },
        formatLv(id){
            const res1 = this.desList.find(item=>item.id == id)
            const res2 = this.rankList.find(item=>item.value == res1.ranks)
            if(res2) return res2.label
        },
        formartAddress(data){
            let str
            if(data.province) str = codeToText[data.province.slice(0,2)]
            if(data.city) str = str + codeToText[data.city.slice(0,4)]
            if(data.region) str = str + codeToText[data.region]
            if(data.address) str = str + data.address
            return str
        },

        callback(data){
            const {store,houseArea,designStyle,houseType,areaCode} = data
            if(store=='-1') delete this.params.salesRoomNo
            else  this.params.salesRoomNo = store

            if(houseArea=='-1') delete this.params.houseArea
            else  this.params.houseArea = houseArea
            
            if(designStyle=='-1') delete this.params.designStyle
            else this.params.designStyle = designStyle

            if(houseType=='-1') delete this.params.houseType
            else this.params.houseType = houseType

            if(areaCode=='-1') delete this.params.areaCode
            else this.params.areaCode = areaCode
            this.params.current = 1
            this.getCaseList()
        }

    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .case {
        overflow-x: hidden;
		.box {
            display: none;
		}
        .box-mob{
            width: 100%;
			height: 160px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            padding-bottom: 30px;
			.filter{
                width: 100%;
            }
            .list{
                margin-top: 25px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 10px;
                box-sizing: border-box;
                .list-item{
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    // height: 360px;
                    overflow: hidden;
                    border-radius: 8px;
                    // margin-left: 50px;
                    background: #fff;
                    margin-bottom: 20px;
                    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.12);
                    .img-box{
                        overflow: hidden;
                        width: 100%;
                        height: 200px;
                        .item-img{
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    
                    .text1{
                        margin-top: 10px;
                        padding: 0 10px;
                        width: 100%;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        
                        font-weight: bold;
                        font-size: 16px;
                        color: #241934;
                        line-height: 16px;
                    }
                    .box1{
                        display: none;  
                    }
                    .mob-box1{
                        width: 100%;
                        padding: 0 10px;
                        margin-top: 5px;
                        box-sizing: border-box;
                    }
                    .bottom{
                        width: 100%;
                        border-top: 1px solid #E5E5E5;
                        height: 50px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        margin-top: 10px;
                        position: relative;
                        .show-dialog{
                            display: flex !important;
                        }
                        .dialog{
                            display: none;
                            position: absolute;
                            top: -160px;
                            left: 0;
                            padding: 10px;
                            width: 120px;
                            height: 160px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                            border-radius: 5px;
                            box-sizing: border-box;
                            .jiao{
                                position: absolute;
                                left: 20px;
                                bottom: -10px;
                                width: 0px;
                                height: 0px;
                                border: 6px solid transparent;
                                border-top-color: #FFFFFF;
                            }
                            .img1{
                                
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;

                            }
                            .text2{
                                
                                font-weight: bold;
                                font-size: 14px;
                                color: #241934;
                                line-height: 14px;
                                margin-top: 10px;

                            }
                            .text3{
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #444444;
                                line-height: 12px;
                                margin-top: 5px;
                            }
                            .options{
                                margin-top: 10px;
                                width: 100%;
                                .btn1{
                                    cursor: pointer;
                                    width: 45px;
                                    height: 20px;
                                    background: #753CBE;
                                    border-radius: 2px;
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #FFFFFF;
                                }
                                .btn2{
                                    cursor: pointer;
                                    width: 45px;
                                    height: 20px;
                                    border-radius: 2px;
                                    border: 1px solid #E5E5E5;
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #444444;
                                }
                            }
                        }
                        
                        .info{
                            cursor: pointer;
                            height: 100%;
                            .avtar{
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                overflow: hidden;
                                box-sizing: border-box;
                                margin-right: 5px;
                                .img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .name{
                                
                                font-weight: bold;
                                font-size: 12px;
                                color: #1B1D22;
                            }
                        }
                        .msg:hover{
                            background: #FF960E;
                        }
                        .msg{
                            cursor: pointer;
                            height: 30px;
                            background: #753CBE;
                            padding: 2px 10px 2px 10px;
                            // background: #753CBE;
                            border-radius: 15px 0 0 15px;
                            box-sizing: border-box;
                            
                            font-weight: 400;
                            color: #FFFFFF;
                            font-size: 12px;
                        }
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
		}
    }
    
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.case {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 2% 70px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.filter{
                width: 100%;
                margin-top: 20px;
            }
            .list{
                // display: flex;
                // flex-wrap: wrap;
                // align-items: center;
                margin-top: 50px;
                // justify-content: flex-start;
                width: 100%;
                display: grid;
                justify-content: space-between;
                grid-template-columns: repeat(auto-fill,32%);
                // gap: 0 10px;
                .list-item:hover{
                    .item-img{
                        transform: scale(1.2);
                    }
                    
                    
                }
                // .list-item:hover{
                //     .bottom{
                //         .msg{
                //             background: #FF960E;
                //         }
                //     }
                // }
                .list-item{
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    // height: 360px;
                    overflow: hidden;
                    border-radius: 18px;
                    // margin-left: 50px;
                    background: #fff;
                    margin-bottom: 50px;
                    box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    .img-box{
                        overflow: hidden;
                        width: 100%;
                        height: 360px;
                    }
                    .item-img{
                        transition: all .6s;
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .text1{
                        cursor: pointer;
                        margin-top: 26px;
                        padding: 0 27px;
                        width: 100%;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #241934;
                        line-height: 36px;
                    }
                    .mob-box1{
                        display: none;
                    }
                    .box1{
                        width: 100%;
                        padding: 0 27px;
                        margin-top: 10px;
                        box-sizing: border-box;
                    }
                    .bottom{
                        width: 100%;
                        border-top: 1px solid #E5E5E5;
                        height: 100px;
                        padding-left: 27px;
                        box-sizing: border-box;
                        margin-top: 10px;
                        position: relative;
                        .show-dialog{
                            display: flex !important;
                        }
                        .dialog{
                            display: none;
                            position: absolute;
                            top: -315px;
                            left: 0;
                            padding: 20px;
                            width: 242px;
                            height: 317px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                            border-radius: 10px;
                            box-sizing: border-box;
                            .jiao{
                                position: absolute;
                                left: 45px;
                                bottom: -22px;
                                width: 0px;
                                height: 0px;
                                border: 12px solid transparent;
                                border-top-color: #FFFFFF;
                            }
                            .img1{
                                cursor: pointer;
                                width: 122px;
                                height: 122px;
                                border-radius: 50%;

                            }
                            .text2{
                                cursor: pointer;
                                
                                font-weight: bold;
                                font-size: 28px;
                                color: #241934;
                                line-height: 28px;
                                margin-top: 15px;

                            }
                            .text3{
                                
                                font-weight: 400;
                                font-size: 24px;
                                color: #444444;
                                line-height: 24px;
                                margin-top: 12px;
                            }
                            .options{
                                margin-top: 22px;
                                width: 100%;
                                .btn1{
                                    cursor: pointer;
                                    width: 90px;
                                    height: 40px;
                                    background: #753CBE;
                                    border-radius: 4px;
                                    
                                    font-weight: 400;
                                    font-size: 20px;
                                    color: #FFFFFF;
                                }
                                .btn2:hover{
                                    background: #753CBE; 
                                    color: #fff;
                                }
                                .btn2{
                                    cursor: pointer;
                                    width: 90px;
                                    height: 40px;
                                    border-radius: 4px;
                                    border: 1px solid #E5E5E5;
                                    
                                    font-weight: 400;
                                    font-size: 20px;
                                    color: #444444;
                                }
                            }
                        }
                        
                        .info{
                            cursor: pointer;
                            height: 100%;
                            .avtar{
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                overflow: hidden;
                                box-sizing: border-box;
                                margin-right: 13px;
                                .img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .name{
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #1B1D22;
                            }
                        }
                        .msg:hover{
                            background: #FF960E;
                        }
                        .msg{
                            cursor: pointer;
                            height: 60px;
                            background: #753CBE;
                            
                            padding: 5px 11px 5px 13px;
                            border-radius: 30px 0 0 30px;
                            box-sizing: border-box;
                            
                            font-weight: 400;
                            color: #FFFFFF;
                            font-size: 28px;
                        }
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
		}
	}
}
@media screen and (min-width: 1360px) {
	.case {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 180px 70px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.filter{
                width: 100%;
                margin-top: 20px;
            }
            .list{
                // display: flex;
                // flex-wrap: wrap;
                // align-items: center;
                margin-top: 50px;
                // justify-content: flex-start;
                width: 100%;
                display: grid;
                justify-content: space-between;
                grid-template-columns: repeat(auto-fill,32%);
                // gap: 0 10px;
                .list-item:hover{
                    .item-img{
                        transform: scale(1.2);
                    }
                    
                    
                }
                // .list-item:hover{
                //     .bottom{
                //         .msg{
                //             background: #FF960E;
                //         }
                //     }
                // }
                .list-item{
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    // height: 360px;
                    overflow: hidden;
                    border-radius: 18px;
                    // margin-left: 50px;
                    background: #fff;
                    margin-bottom: 50px;
                    box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    .img-box{
                        overflow: hidden;
                        width: 100%;
                        height: 360px;
                    }
                    .item-img{
                        transition: all .6s;
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .text1{
                        cursor: pointer;
                        margin-top: 26px;
                        padding: 0 27px;
                        width: 100%;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #241934;
                        line-height: 36px;
                    }
                    .mob-box1{
                        display: none;
                    }
                    .box1{
                        width: 100%;
                        padding: 0 27px;
                        margin-top: 10px;
                        box-sizing: border-box;
                    }
                    .bottom{
                        width: 100%;
                        border-top: 1px solid #E5E5E5;
                        height: 100px;
                        padding-left: 27px;
                        box-sizing: border-box;
                        margin-top: 10px;
                        position: relative;
                        .show-dialog{
                            display: flex !important;
                        }
                        .dialog{
                            display: none;
                            position: absolute;
                            top: -315px;
                            left: 0;
                            padding: 20px;
                            width: 242px;
                            height: 317px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                            border-radius: 10px;
                            box-sizing: border-box;
                            .jiao{
                                position: absolute;
                                left: 45px;
                                bottom: -22px;
                                width: 0px;
                                height: 0px;
                                border: 12px solid transparent;
                                border-top-color: #FFFFFF;
                            }
                            .img1{
                                cursor: pointer;
                                width: 122px;
                                height: 122px;
                                border-radius: 50%;

                            }
                            .text2{
                                cursor: pointer;
                                
                                font-weight: bold;
                                font-size: 28px;
                                color: #241934;
                                line-height: 28px;
                                margin-top: 15px;

                            }
                            .text3{
                                
                                font-weight: 400;
                                font-size: 24px;
                                color: #444444;
                                line-height: 24px;
                                margin-top: 12px;
                            }
                            .options{
                                margin-top: 22px;
                                width: 100%;
                                .btn1{
                                    cursor: pointer;
                                    width: 90px;
                                    height: 40px;
                                    background: #753CBE;
                                    border-radius: 4px;
                                    
                                    font-weight: 400;
                                    font-size: 20px;
                                    color: #FFFFFF;
                                }
                                .btn2:hover{
                                    background: #753CBE; 
                                    color: #fff;
                                }
                                .btn2{
                                    cursor: pointer;
                                    width: 90px;
                                    height: 40px;
                                    border-radius: 4px;
                                    border: 1px solid #E5E5E5;
                                    
                                    font-weight: 400;
                                    font-size: 20px;
                                    color: #444444;
                                }
                            }
                        }
                        
                        .info{
                            cursor: pointer;
                            height: 100%;
                            .avtar{
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                overflow: hidden;
                                box-sizing: border-box;
                                margin-right: 13px;
                                .img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .name{
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #1B1D22;
                            }
                        }
                        .msg:hover{
                            background: #FF960E;
                        }
                        .msg{
                            cursor: pointer;
                            height: 60px;
                            background: #753CBE;
                            
                            padding: 5px 11px 5px 13px;
                            border-radius: 30px 0 0 30px;
                            box-sizing: border-box;
                            
                            font-weight: 400;
                            color: #FFFFFF;
                            font-size: 28px;
                        }
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
		}
	}
}
</style>
